import type { ThemeOverrides } from '@qasa/qds-ui'

const fontFamily = 'BlocketSans'
const blocketBrandPrimary = {
  brand90: '#9C0E0E',
  brand80: '#C81111',
  brand70: '#E91111',
  brand60: '#F71414',
  brand50: '#FB2A2A',
  brand40: '#FF6464',
  brand30: '#F4AFAF',
  brand20: '#FFD3D3',
  brand10: '#FEF3F3',
}

export const blocketQdsOverrides: ThemeOverrides = {
  colors: {
    core: {
      uiPink: blocketBrandPrimary.brand60,
      uiPinkLight: blocketBrandPrimary.brand50,
      uiPinkDark: blocketBrandPrimary.brand70,
      softPink: blocketBrandPrimary.brand20,
    },
    bg: {
      brandPrimary: blocketBrandPrimary.brand60,
      brandPrimaryHover: blocketBrandPrimary.brand50,
      brandPrimaryActive: blocketBrandPrimary.brand70,
    },
    text: {
      onBrandPrimary: '#ffffff',
    },
  },
  typography: {
    display: {
      fontFamily,
      fontWeight: '600',
    },
    title: {
      fontFamily,
      fontWeight: '500',
    },
    body: {
      fontFamily,
    },
    caption: {
      fontFamily,
    },
    label: {
      fontFamily,
      fontWeight: '500',
    },
    button: {
      fontFamily,
      fontWeight: '500',
    },
  },
}
