'use client'

import styled from '@emotion/styled'
import { useToastContext } from '@qasa/app'
import { AnimatedToastList } from '@qasa/app/src/components/animated-toast-list'

const ToastsListContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing['2x'],
  position: 'fixed',
  bottom: theme.spacing['8x'],
  left: theme.spacing['4x'],
  minWidth: theme.sizes['320'],
  maxWidth: theme.sizes['448'],
  width: `calc(100vw - ${theme.spacing['8x']})`,
  zIndex: theme.zIndices.toast,
}))

export function Toasts() {
  const { toasts } = useToastContext()

  return (
    <ToastsListContainer>
      <AnimatedToastList toasts={toasts} />
    </ToastsListContainer>
  )
}
