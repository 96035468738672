import { Button, Paragraph, Stack, styled } from '@qasa/ui'

type ToastProps = {
  message: string
  actionLabel?: string
  onAction?: () => void
}

const ToastContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.colors.core.black,
  paddingTop: theme.spacing['3x'],
  paddingBottom: theme.spacing['3x'],
  paddingLeft: theme.spacing['4x'],
  paddingRight: theme.spacing['4x'],
  borderRadius: theme.radii.md,
}))

const StyledParagraph = styled(Paragraph)(({ theme }) => ({
  flex: 1,
  color: theme.colors.core.white,
}))

const ToastActionButton = styled(Button)({
  marginLeft: 'auto',
})

export function Toast({ message, actionLabel, onAction }: ToastProps) {
  if (!message) {
    return null
  }

  return (
    <ToastContainer alignItems="center" direction="row" gap={'4x'}>
      <StyledParagraph>{message}</StyledParagraph>
      {actionLabel && onAction && (
        <ToastActionButton onPress={onAction} variant="tertiary">
          {actionLabel}
        </ToastActionButton>
      )}
    </ToastContainer>
  )
}
