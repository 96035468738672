import { useServerInsertedHTML } from 'next/navigation'
import createCache from '@emotion/cache'
import { useState } from 'react'

// Copied from https://github.com/emotion-js/emotion/issues/2928#issuecomment-1319747902
export const useEmotionCache = () => {
  const [{ cache, flush }] = useState(() => {
    const cache = createCache({ key: 'qds' })
    cache.compat = true
    const prevInsert = cache.insert
    let inserted: string[] = []
    cache.insert = (...args) => {
      const serialized = args[1]
      if (cache.inserted[serialized.name] === undefined) {
        inserted.push(serialized.name)
      }
      return prevInsert(...args)
    }
    const flush = () => {
      const prevInserted = inserted
      inserted = []
      return prevInserted
    }
    return { cache, flush }
  })

  useServerInsertedHTML(() => {
    const names = flush()
    if (names.length === 0) return null
    let styles = ''
    for (const name of names) {
      styles += cache.inserted[name]
    }
    return (
      <style
        data-emotion={`${cache.key} ${names.join(' ')}`}
        dangerouslySetInnerHTML={{
          __html: styles,
        }}
      />
    )
  })

  return cache
}
