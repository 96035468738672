'use client'

import Script from 'next/script'

import { ENV } from '../helpers/env'

const GOOGLE_TAG_MANAGER_ID = ENV.GOOGLE_TAG_MANAGER_ID
const GOOGLE_ANALYTICS_TRACKING_ID = ENV.GOOGLE_ANALYTICS_TRACKING_ID

export function GoogleAnalytics() {
  return (
    <>
      <Script
        id="google-consent-script"
        dangerouslySetInnerHTML={{
          __html: `
          // Define dataLayer and the gtag function.
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
        
          // Set default consent to 'denied' as a placeholder
          // Determine actual values based on your own requirements
          gtag('consent', 'default', {
            'ad_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'analytics_storage': 'denied'
          });
          `,
        }}
      />
      {GOOGLE_TAG_MANAGER_ID && (
        <Script
          id="google-tag-manager-script"
          dangerouslySetInnerHTML={{
            __html: `
            ;(function (w, d, s, l, i) {
              w[l] = w[l] || []
              w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : ''
              j.async = true
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
              f.parentNode.insertBefore(j, f)
            })(window, document, 'script', 'dataLayer', '${GOOGLE_TAG_MANAGER_ID}')
            `,
          }}
        />
      )}
      {GOOGLE_ANALYTICS_TRACKING_ID && (
        <>
          <Script src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_TRACKING_ID}`} />
          <Script
            id="google-analytics-script"
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || []
              function gtag() {
                dataLayer.push(arguments)
              }
              gtag('js', new Date())
              gtag('config', '${GOOGLE_ANALYTICS_TRACKING_ID}', { send_page_view: false })
              `,
            }}
          />
        </>
      )}
    </>
  )
}
