'use client'

import { useEffect } from 'react'
import { ampli } from '@qasa/ampli/p2'
import { trackPageView } from '@qasa/app'

import { usePathname } from '../vendor/next'

const getGtagObjectType = (pathname: string) => {
  if (pathname.startsWith('/home/')) {
    return 'ClassifiedAd'
  } else if (['/find-home', '/embedded-find-home'].includes(pathname)) {
    return 'Listing'
  }

  return 'Page'
}

export function PageViewTracking() {
  const pathname = usePathname()

  useEffect(() => {
    ampli.amplitudePageViewed({ '[Amplitude] Page URL': window.location.href })
    trackPageView(pathname, getGtagObjectType(pathname))
  }, [pathname])

  return null
}
