import { forwardRef } from 'react'
import * as SwitchPrimitive from '@radix-ui/react-switch'
import styled from '@emotion/styled'

const Root = styled(SwitchPrimitive.Root)(({ theme }) => ({
  all: 'unset',
  position: 'relative',
  width: theme.sizes['16x'],
  height: theme.sizes['8x'],
  backgroundColor: theme.colors.core.blackAlpha20,
  borderRadius: theme.radii.full,
  boxShadow: theme.shadows.md,
  transition: 'background-color 100ms',
  '&:focus': {
    boxShadow: theme.shadows.sm,
  },
  '&[data-state="checked"]': {
    backgroundColor: theme.colors.bg.brandSecondary,
  },
  '&[data-disabled]': {
    pointerEvents: 'none',
    opacity: 0.7,
  },
}))

const Thumb = styled(SwitchPrimitive.Thumb)(({ theme }) => ({
  display: 'block',
  width: theme.sizes['6x'],
  height: theme.sizes['6x'],
  backgroundColor: theme.colors.bg.default,
  borderRadius: theme.radii.full,
  boxShadow: theme.shadows.sm,
  transition: 'transform 100ms',
  transform: 'translateX(4px)',
  '&[data-state="checked"]': { transform: 'translateX(36px)' },
}))

export const Switch = forwardRef<HTMLButtonElement, SwitchPrimitive.SwitchProps>((props, ref) => {
  return (
    <Root {...props} ref={ref}>
      <Thumb />
    </Root>
  )
})
