import { Fragment, useEffect } from 'react'
import { initReactI18next } from 'react-i18next'
import i18next from 'i18next'
import { I18nextProvider } from '@qasa/app'
import moment from 'moment'
import Cookies from 'js-cookie'

import { useCurrentLocale } from '../use-current-locale'
import { currentBrand } from '../../config'

import { dev, en, sv, fr, fi, nb } from './build-resources'
import { getCurrentLocale } from './language-utils'

const momentJsLocales = {
  en: () => Promise.resolve(),
  // @ts-expect-error
  fr: () => import('moment/locale/fr'),
  // @ts-expect-error
  sv: () => import('moment/locale/sv'),
  // @ts-expect-error
  fi: () => import('moment/locale/fi'),
  // @ts-expect-error
  nb: () => import('moment/locale/nb'),
}

const isServerSide = typeof window === 'undefined'

i18next
  .use(initReactI18next)
  .use({
    type: 'languageDetector',
    detect: () => {
      if (isServerSide) {
        return undefined
      }

      return getCurrentLocale()
    },
  })
  .init({
    resources: {
      dev,
      en,
      sv,
      fr,
      fi,
      nb,
    },
    lng: undefined,
    fallbackLng: 'dev',
    interpolation: {
      escapeValue: false,
      skipOnVariables: false,
    },
    defaultNS: 'commons',
    compatibilityJSON: 'v3',
    preload: isServerSide ? ['dev', 'en', 'sv', 'fr', 'fi', 'nb'] : [],
  })

/**
 * This is to temporarily allow Finnish and Norwegian on qasa.com for select users (devs),
 * so we don't have to translate everything at once. Just set a cookie in devtools
 * with the name "worldDomination" and value "fi" or "nb"
 *
 */
const getDevLanguageOnDotcom = () => {
  const devLanguage = Cookies.get('worldDomination')
  const shouldUseDevLanguage = currentBrand === 'dotcom' && (devLanguage === 'fi' || devLanguage === 'nb')
  return shouldUseDevLanguage ? devLanguage : null
}

export function LanguageLoader({ children }: { children: React.ReactNode }) {
  const lngFromLocale = useCurrentLocale()

  const lng = getDevLanguageOnDotcom() || lngFromLocale

  useEffect(() => {
    momentJsLocales[lng]().then(() => {
      moment.locale(lng)
    })
  }, [lng])

  if (isServerSide && i18next.language !== lng) {
    i18next.changeLanguage(lng)
  }

  useEffect(() => {
    if (i18next.language === lng) {
      return
    }

    i18next.changeLanguage(lng)
  }, [lng])

  return (
    <Fragment key={lng}>
      <I18nextProvider currentLanguage={lng}>{children}</I18nextProvider>
    </Fragment>
  )
}
